import { useTcuIdentity } from "../state";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function StepButtonBar({
  onContinue,
  onRetry,
  onSkip,
  success,
  disabled,
}: {
  onContinue: () => void;
  onRetry: () => void;
  onSkip?: () => void;
  success: boolean;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  const { vin } = useTcuIdentity();

  const skipEnabled = !!onSkip;
  const canContinue = success && !disabled;
  const showSkip = !success && !disabled && skipEnabled;
  const buttonTooltipKey = canContinue
    ? "continue"
    : showSkip
      ? "skip"
      : "failed_no_skip";

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack
        alignItems="baseline"
        alignSelf="end"
        direction="row"
        justifySelf="start"
        spacing={1}
      >
        <Typography color="text.secondary" variant="body2">
          {t("vin_short_colon")}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {vin}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Tooltip title={disabled ? "" : t("step_retry")}>
          <span>
            <IconButton
              aria-label={t("step_retry")}
              disabled={disabled}
              onClick={onRetry}
            >
              <ReplayIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t(`continue_button.${buttonTooltipKey}`)}>
          <span>
            <Button
              color={showSkip ? "error" : "success"}
              disabled={!(canContinue || showSkip)}
              onClick={() => (showSkip ? onSkip() : onContinue())}
              variant="contained"
            >
              {showSkip ? t("step_skip") : t("step_continue")}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
