import { TestRun } from "./TestRun";
import { AUTH_PROVIDER, VERSION } from "./constants";
import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Box,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthProvider, useAuth } from "react-oidc-context";

function LoginButton() {
  const { t } = useTranslation();
  const auth = useAuth();

  let toggleLoginText;
  if (auth.isAuthenticated) toggleLoginText = t("logout");
  else toggleLoginText = t("login");

  return (
    <LoadingButton
      color="inherit"
      loading={auth.isLoading}
      onClick={() => {
        if (auth.isAuthenticated) auth.signoutRedirect();
        else auth.signinPopup();
      }}
    >
      {toggleLoginText}
    </LoadingButton>
  );
}

function AppInner() {
  const { t } = useTranslation();
  const auth = useAuth();

  let content;
  if (auth.isAuthenticated) content = <TestRun />;
  else
    content = (
      <Box>
        <Typography gutterBottom variant="h2">
          {t("login_page.title")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("login_page.description")}
        </Typography>
      </Box>
    );

  return (
    <>
      <AppBar position="static" sx={{ mb: 2 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" component="span" sx={{ mr: 1 }}>
              {t("title")}
            </Typography>
            <Typography color="secondary" component="span" variant="subtitle2">
              {`v${VERSION}`}
            </Typography>
          </Box>
          <LoginButton />
        </Toolbar>
      </AppBar>
      <Container>{content}</Container>
    </>
  );
}

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode],
  );

  return (
    <AuthProvider
      automaticSilentRenew={true}
      authority={AUTH_PROVIDER.authority}
      client_id={AUTH_PROVIDER.clientId}
      redirect_uri={window.location.href}
    >
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppInner />
        </ThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
