import { VehicleModel } from "./vehicle";

export const AirSuspensionParameters = {
  heightToleranceCm: 1.0,
  expectedRaisedHeightCm: 47.0,
  expectedLoweredHeightCm: 42.0,
  skipIf: ({ vehicleModel }: { vehicleModel: VehicleModel }) => {
    // skip air suspension test if the vehicle model is a Ford 1T since it doesn't have air suspension
    return vehicleModel === "ford-1t";
  },
};

export const ChargingParameters = {
  signalUpdateIntervalMs: 10000,
  socThreshold: 90,
  powerTolerance: 200,
  powerCommandDuration: 60 * 20,
  startChargingPower: 2500,
  stopChargingPower: 0,
  unlockChargingPower: 200000,
  unlockCommandDuration: 7 * 24 * 60 * 60,
  unlockIncludeFallback: true,
};

export const GpsParameters = {
  maxGpsDop: 20.0,
};

export const ConnectivityParameters = {
  minRssi: 10.0,
};
