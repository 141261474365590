import * as api from "../api";
import { TestHeader } from "../components/TestHeader";
import { TcuIdentity } from "../state";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

// examples: sts-tcu12345, 12345, sts-tcuB12345
const TCU_SN_RE = /^(?:sts-tcu)?B?\d{3,6}$/;

const VIN_CHARS_RE = /^[A-HJ-NPR-Z0-9]+$/;
const VIN_LENGTH = 17;

type QueryType = "vin" | "sn";

type ValidateQueryError =
  | "invalid_length"
  | "invalid_character"
  | "tcu_missing_vin"
  | "tcu_not_found";

type Query = {
  type: QueryType;
  value: string;
  validationError: ValidateQueryError | null;
};

function getQuery(value: string, full: boolean): Query {
  let type: QueryType;
  let validationError;
  if (TCU_SN_RE.test(value) && value.length !== VIN_LENGTH) {
    type = "sn";
    validationError = validateSerialNumber(value);
  } else {
    type = "vin";
    validationError = validateVin(value, full);
  }
  return { type, value, validationError };
}

function validateVin(vin: string, full: boolean): ValidateQueryError | null {
  if (vin.length === 0) return null;

  if (!VIN_CHARS_RE.test(vin)) {
    return "invalid_character";
  }

  const validLength = full
    ? vin.length === VIN_LENGTH
    : vin.length <= VIN_LENGTH;
  if (!validLength) return "invalid_length";

  return null;
}

function validateSerialNumber(sn: string): ValidateQueryError | null {
  if (sn.length === 0) return null;

  if (!TCU_SN_RE.test(sn)) {
    return "invalid_character";
  }

  return null;
}

interface Props {
  onTcuIdentified: (id: TcuIdentity) => void;
}

export function IdentifyStep({ onTcuIdentified }: Props) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const accessToken = api.useAccessToken();
  const [query, setQuery] = React.useState<Query>(() => ({
    type: "vin",
    value: "",
    validationError: null,
  }));
  const [loading, setLoading] = React.useState(false);
  const doSubmit = React.useCallback(() => {
    if (loading || query.value === "") return;

    setLoading(true);
    let promise;
    switch (query.type) {
      case "vin":
        let vin = query.value.toUpperCase();
        promise = api.tcuInfoForVin(null, accessToken, vin);
        break;
      case "sn":
        let boxId = query.value;
        if (!boxId.startsWith("sts-tcu")) {
          boxId = `sts-tcu${boxId}`;
        }
        promise = api.tcuInfoForBoxId(null, accessToken, boxId);
        break;
    }
    promise
      .then((info) => {
        setLoading(false);
        if (!info.found) {
          setQuery((query) => ({ ...query, validationError: "tcu_not_found" }));
          return;
        }
        if (info.vin === null) {
          setQuery((query) => ({
            ...query,
            validationError: "tcu_missing_vin",
          }));
          return;
        }

        onTcuIdentified({
          vin: info.vin,
          // if 'found' is true, 'box_id' is present
          boxId: info.box_id ?? "",
        });
      })
      .catch((err) => {
        setLoading(false);
        api.errorNotifyUser(err, { snackbar, t });
      });
  }, [accessToken, loading, onTcuIdentified, snackbar, t, query]);

  const startEnabled = query.validationError === null && query.value !== "";

  return (
    <Box>
      <TestHeader testKey="identify" />
      <Stack spacing={2} sx={{ my: 3 }}>
        <TextField
          autoFocus
          error={query.validationError !== null}
          helperText={
            query.validationError !== null
              ? t(`identify.query_validation.${query.validationError}`)
              : null
          }
          label={t("identify.query_long")}
          onBlur={() => {
            if (query.value.length > 0) {
              // validate full query on blur
              setQuery(getQuery(query.value, true));
            }
          }}
          onChange={(ev) => {
            const newValue = ev.target.value;
            setQuery(getQuery(newValue, false));
          }}
          onKeyUp={(ev) => {
            if (ev.key === "Enter" && startEnabled) doSubmit();
          }}
          placeholder="WF0ABCDEFGHI12345"
          required
          variant="outlined"
        />
        <LoadingButton
          disabled={!startEnabled}
          loading={loading}
          onClick={doSubmit}
          size="large"
          variant="contained"
        >
          {t("identify.start_test")}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
