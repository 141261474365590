import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

export function VerdictIcon({
  success,
  error,
}: {
  success: boolean;
  error?: boolean;
}) {
  if (success) return <DoneIcon color="success" />;
  else if (error === true) return <ErrorIcon color="error" />;
  else return <CloseIcon color="error" />;
}
