import * as api from "../api";
import { StepButtonBar } from "../components/StepButtonBar";
import { TestHeader } from "../components/TestHeader";
import { VerdictIcon } from "../components/VerdictIcon";
import { GeneralTestResult, useTcuIdentity } from "../state";
import { GpsParameters } from "../test-parameters";
import RoomIcon from "@mui/icons-material/Room";
import SignalCellular0BarIcon from "@mui/icons-material/SignalCellular0Bar";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import L from "leaflet";
import leafletIconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import leafletIconUrl from "leaflet/dist/images/marker-icon.png";
import leafletShadowUrl from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

L.Marker.prototype.options.icon = L.icon({
  // take the default icon options...
  ...L.Icon.Default.prototype.options,
  /// ... but fix the icon URLs
  iconRetinaUrl: leafletIconRetinaUrl,
  iconUrl: leafletIconUrl,
  shadowUrl: leafletShadowUrl,
});

function TcuPositionKnown({
  lat,
  lon,
}: {
  lat: number | null;
  lon: number | null;
}) {
  const DEFAULT_TILE_LAYER = (
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  );

  const COMMON_MAP_CONTAINER_OPTIONS = {
    scrollWheelZoom: false,
    style: { height: "400px" },
  };

  if (lat === null || lon === null) {
    return (
      <MapContainer
        center={[46.801111, 8.226667]}
        zoom={2}
        {...COMMON_MAP_CONTAINER_OPTIONS}
      >
        {DEFAULT_TILE_LAYER}
      </MapContainer>
    );
  }

  const position = [lat, lon] as [number, number];
  return (
    <MapContainer center={position} zoom={15} {...COMMON_MAP_CONTAINER_OPTIONS}>
      {DEFAULT_TILE_LAYER}
      <Marker position={position} />
    </MapContainer>
  );
}

function ResultDisplay({ result }: { result: GpsResult }) {
  const { t } = useTranslation();

  const success = result.success === true;
  const signalStrength = result.signalStrength;

  let strengthIcon;
  switch (signalStrength) {
    case "Ideal":
      strengthIcon = <SignalCellular4BarIcon color="success" />;
      break;
    case "Excellent":
      strengthIcon = <SignalCellular4BarIcon color="success" />;
      break;
    case "Good":
      strengthIcon = <SignalCellular3BarIcon color="success" />;
      break;
    case "Moderate":
      strengthIcon = <SignalCellular2BarIcon color="warning" />;
      break;
    case "Fair":
      strengthIcon = <SignalCellular1BarIcon color="warning" />;
      break;
    case "Poor":
      strengthIcon = <SignalCellular0BarIcon color="error" />;
      break;
    default:
      strengthIcon = (
        <SignalCellularConnectedNoInternet0BarIcon color="error" />
      );
      break;
  }

  return (
    <List>
      <ListItem divider>
        <ListItemIcon>{strengthIcon}</ListItemIcon>
        <ListItemText
          primary={t("gps.signal_strength")}
          secondary={t(`gps.signal_strengths.${signalStrength}`)}
        />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>
          <RoomIcon color="info" />
        </ListItemIcon>
        <ListItemText>
          <TcuPositionKnown
            lat={result.apiData?.latitude ?? null}
            lon={result.apiData?.longitude ?? null}
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <VerdictIcon success={success} error={result.apiError !== null} />
        </ListItemIcon>
        <ListItemText
          primary={t("verdict")}
          secondary={t(`boolean_verdict.${success}`)}
        />
      </ListItem>
    </List>
  );
}

export interface GpsResult extends GeneralTestResult {
  signalStrength: api.TcuPosition["signal_strength"];
  apiData: api.TcuPosition | null;
  apiError: api.ErrorInfo | null;
}

export function GpsStep({
  onResult,
}: {
  onResult: (result: GpsResult) => void;
}) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { boxId } = useTcuIdentity();
  const accessToken = api.useAccessToken();

  const [gpsResult, setGpsResult] = React.useState<GpsResult | null>(null);
  const [loading, setLoading] = React.useState(true);
  const doApiCall = React.useCallback(
    async (abortSignal?: AbortSignal) => {
      setLoading(true);
      try {
        const apiData = await api.getTcuPosition(
          abortSignal || null,
          accessToken,
          boxId,
        );
        const success =
          apiData.dilution_of_precision === null
            ? false
            : apiData.dilution_of_precision <= GpsParameters.maxGpsDop;
        setGpsResult({
          performedAt: DateTime.now(),
          success,
          signalStrength: apiData.signal_strength,
          apiData,
          apiError: null,
        });
      } catch (err) {
        if (api.errIsManualAbort(err)) return;

        api.errorNotifyUser(err, { snackbar, t });

        let apiError = null;
        if (err instanceof api.ApiError) {
          apiError = err.error;
        }

        setGpsResult({
          performedAt: DateTime.now(),
          success: false,
          signalStrength: "Unknown",
          apiData: null,
          apiError: apiError,
        });
      }

      setLoading(false);
    },
    [boxId, t, snackbar, accessToken],
  );
  React.useEffect(() => {
    if (gpsResult !== null) return;

    const abortController = new AbortController();
    doApiCall(abortController.signal);
    return () => abortController.abort();
  }, [doApiCall, gpsResult]);

  let content;
  if (loading || !gpsResult) {
    content = (
      <Box sx={{ p: 3 }}>
        <Typography gutterBottom>{t("waiting_for_answer")}</Typography>
        <LinearProgress />
      </Box>
    );
  } else {
    content = <ResultDisplay result={gpsResult} />;
  }

  const success = gpsResult?.success ?? false;

  return (
    <Box>
      <TestHeader testKey="gps" />
      <Paper elevation={1} sx={{ my: 3 }}>
        {content}
      </Paper>
      <StepButtonBar
        onContinue={() => {
          if (gpsResult) onResult(gpsResult);
        }}
        onSkip={() => {
          if (gpsResult) onResult(gpsResult);
        }}
        onRetry={() => {
          doApiCall();
        }}
        success={success}
        disabled={loading}
      />
    </Box>
  );
}
