import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function TestHeader({ testKey }: { testKey: string }) {
  const { t } = useTranslation();

  return (
    <>
      <Typography gutterBottom variant="h2">
        {t(`${testKey}.title` as unknown as any)}
      </Typography>
      <Typography gutterBottom variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {t(`${testKey}.description` as unknown as any)}
      </Typography>
    </>
  );
}
