import * as api from "../api";
import { TestHeader } from "../components/TestHeader";
import { VERSION } from "../constants";
import { createEolReport, State, TestResults } from "../state";
import { AirSuspensionResult } from "./AirSuspension";
import { ChargingResult } from "./Charging";
import { ConnectivityResult } from "./Connectivity";
import { GpsResult } from "./Gps";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable, { CellDef, RowInput } from "jspdf-autotable";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

export function OutputResultsStep({
  state,
  onNewTest,
}: {
  state: State;
  onNewTest: () => void;
}) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const accessToken = api.useAccessToken();
  const eolReport = React.useMemo(() => createEolReport(state), [state]);
  const fileName = React.useMemo(() => {
    const startAt = state.startAt ?? DateTime.now();
    return `${state.vin}_${startAt.toFormat("yyyy-MM-dd_hh-mm")}.pdf`;
  }, [state.vin, state.startAt]);
  const [loading, setLoading] = React.useState(false);
  const [reportId, setReportId] = React.useState<string | null>(null);
  const onUpload = React.useCallback(async () => {
    if (loading || reportId !== null) return;

    setLoading(true);
    let uploadedReportId = null;
    if (eolReport !== null) {
      try {
        uploadedReportId = await api.uploadEolReport(
          null,
          accessToken,
          eolReport,
        );
      } catch (err) {
        if (api.errIsManualAbort(err)) return;
        api.errorNotifyUser(err, { snackbar, t });
      }
    }

    try {
      await createPdf(state, uploadedReportId).save(fileName);
    } catch (err) {
      api.errorNotifyUser(err, { snackbar, t });
    }

    setReportId(uploadedReportId);
    setLoading(false);
  }, [
    accessToken,
    eolReport,
    fileName,
    loading,
    reportId,
    setLoading,
    setReportId,
    snackbar,
    state,
    t,
  ]);

  return (
    <Box>
      <TestHeader testKey="results" />
      <Stack direction="column" spacing={3} sx={{ mt: 10 }}>
        <LoadingButton
          color="primary"
          disabled={reportId !== null}
          loading={loading}
          onClick={() => onUpload()}
          size="large"
          startIcon={<CloudUploadIcon />}
          variant="contained"
        >
          {t("results.persist")}
        </LoadingButton>
        <Tooltip
          title={
            reportId === null ? t("results.start_new_test_tooltip_warn") : ""
          }
        >
          <Button
            color={reportId === null ? "warning" : "secondary"}
            onClick={onNewTest}
            size="large"
            startIcon={reportId === null ? <PriorityHighIcon /> : undefined}
            variant="contained"
          >
            {t("results.start_new_test")}
          </Button>
        </Tooltip>
      </Stack>
    </Box>
  );
}

const TOTAL_PAGES_EXP = "{total_pages_count}";

function createPdf(state: State, reportId: string | null): jsPDF {
  const doc = new jsPDF();

  autoTable(doc, {
    body: [
      ["VIN", state.vin],
      ["TCU ID", state.boxId],
      [
        "Test Started",
        state.startAt?.toLocaleString(DateTime.DATETIME_MED) ?? null,
      ],
      [
        "Test Ended",
        state.endAt?.toLocaleString(DateTime.DATETIME_MED) ?? null,
      ],
      ["Report ID", reportId],
      ["TET version", VERSION],
    ],
    theme: "plain",
    margin: { top: 60 },
    didDrawPage: (data) => {
      doc.setFontSize(20);
      doc.text("TCU EOL Report", data.settings.margin.left, 22);
      drawPageFooter(
        doc,
        data.settings.margin.left,
        data.settings.margin.right,
      );
    },
  });

  doc.addPage();
  renderTestsTable(doc, state.tests);
  doc.putTotalPages(TOTAL_PAGES_EXP);
  return doc;
}

function drawPageFooter(doc: jsPDF, marginLeft: number, marginRight: number) {
  doc.setFontSize(10);
  const pageHeight = doc.internal.pageSize.getHeight();
  doc.text(
    `Page ${doc.getNumberOfPages()} of ${TOTAL_PAGES_EXP}`,
    marginLeft,
    pageHeight - 10,
  );
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.text("INOMO Technologies", pageWidth - marginRight, pageHeight - 10, {
    align: "right",
  });
}

function renderTestsTable(doc: jsPDF, tests: TestResults) {
  const body = [];
  if (tests.connectivity !== null)
    body.push(...connectivityTableBody(tests.connectivity));
  if (tests.charging !== null) body.push(...chargingTableBody(tests.charging));
  if (tests.gps !== null) body.push(...gpsTableBody(tests.gps));
  if (tests.airSuspension !== null)
    body.push(...airSuspensionTableBody(tests.airSuspension));

  autoTable(doc, {
    head: [["Test Name", "Test Verdict", "Step Name", "Step Verdict", "Note"]],
    body,
    theme: "grid",
    margin: { top: 30 },
    didDrawPage: (data) => {
      doc.setFontSize(20);
      doc.text("Test Results", data.settings.margin.left, 22);
      drawPageFooter(
        doc,
        data.settings.margin.left,
        data.settings.margin.right,
      );
    },
  });
}

function verdictText(success: boolean | null): CellDef {
  switch (success) {
    case true:
      return { content: "PASSED", styles: { valign: "middle" } };
    case false:
      return {
        content: "FAILED",
        styles: { textColor: "red", valign: "middle" },
      };
    case null:
      return {
        content: "SKIPPED",
        styles: { textColor: "red", valign: "middle" },
      };
  }
}

function connectivityTableBody(result: ConnectivityResult): RowInput[] {
  const ROWS = 2;

  return [
    [
      {
        rowSpan: ROWS,
        content: "Connectivity",
        styles: { valign: "middle" },
      },
      {
        ...verdictText(result.success),
        rowSpan: ROWS,
      },
      "Connected",
      verdictText(result.connected),
      null,
    ],
    ["Signal Strength", verdictText(result.success), result.signalStrength],
  ];
}

function chargingTableBody(result: ChargingResult): RowInput[] {
  const ROWS = 4;

  return [
    [
      {
        rowSpan: ROWS,
        content: "Charging",
        styles: { valign: "middle" },
      },
      {
        ...verdictText(result.success),
        rowSpan: ROWS,
      },
      "Preparation",
      verdictText(result.preparation !== null),
      null,
    ],
    ["Plug In", verdictText(result.plugIn !== null), null],
    ["Start Charging", verdictText(result.startCharging !== null), null],
    ["Stop Charging", verdictText(result.stopCharging !== null), null],
  ];
}

function gpsTableBody(result: GpsResult): RowInput[] {
  const ROWS = 1;

  return [
    [
      {
        rowSpan: ROWS,
        content: "GPS",
        styles: { valign: "middle" },
      },
      {
        ...verdictText(result.success),
        rowSpan: ROWS,
      },
      "Signal Strength",
      verdictText(result.success),
      result.signalStrength,
    ],
  ];
}

function airSuspensionTableBody(result: AirSuspensionResult): RowInput[] {
  const ROWS = 3;

  const getNote = (height: number | undefined) => {
    if (height === undefined) return null;
    else return `${height} cm`;
  };

  return [
    [
      {
        rowSpan: ROWS,
        content: "Air Suspension",
        styles: { valign: "middle" },
      },
      {
        ...verdictText(result.success),
        rowSpan: ROWS,
      },
      "Raise",
      verdictText(result.raised?.passed ?? null),
      getNote(result.raised?.measuredHeightCm),
    ],
    [
      "Lower",
      verdictText(result.lowered?.passed ?? null),
      getNote(result.lowered?.measuredHeightCm),
    ],
    ["Disabled", verdictText(result.disabledPassed), null],
  ];
}
