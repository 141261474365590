export type VehicleModel =
  | "ford-1t"
  | "ford-2t"
  | "ford-unknown"
  | "unknown-unknown";

const PREFIX_FORD_1T = "WF0RXX";
const PREFIX_FORD_2T = ["WF0AXX", "WF0EXX"];
const PREFIX_FORD = "WF0";

export function identifyVehicleModel(vin: string): VehicleModel {
  if (vin.startsWith(PREFIX_FORD_1T)) return "ford-1t";
  if (PREFIX_FORD_2T.some((prefix) => vin.startsWith(prefix))) return "ford-2t";
  if (vin.startsWith(PREFIX_FORD)) return "ford-unknown";
  return "unknown-unknown";
}
